import React, {useEffect, useState} from 'react';
import {isMobile, MobileView} from "react-device-detect";
import {adDisplayDelay, delayMultiplicationFactor} from "../../utils/articleUtil";

const AdBottomSticky = () => {
    /*const [displayBottomSticky, setDisplayBottomSticky] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (window.googletag && isMobile) {
                googletag.cmd.push(function () {
                    try {
                        googletag.display('div-pt_sticky_mobile');
                    } catch (e) {

                    }
                });
            }
            if (isMobile && window.location.pathname === '/') {
                const bottomSticky = document.getElementsByClassName('ad-sticky-container bottom')
                if (bottomSticky && bottomSticky.length) {
                    bottomSticky[0].classList.add("bottom-0");
                }
            }
        }, adDisplayDelay() * delayMultiplicationFactor(isMobile));
        return () => {
            clearTimeout(timeout);
        };
    }, []);*/

    return <>
        {/*{displayBottomSticky && <MobileView>
            <div className="ad-sticky-container bottom">
                <button className="close-it advertisement-text" onClick={() => setDisplayBottomSticky(false)}>x
                </button>
                <div className="ad-container" id="div-pt_sticky_mobile"></div>
            </div>
        </MobileView>}*/}
    </>
}

export default AdBottomSticky;
